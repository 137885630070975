import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useWeavy, WyMessenger } from "@weavy/uikit-react";
import { WeavyApi } from "certiblok-api-manager";
import { useAtomValue } from "jotai";
import { useEffect, useRef } from "react";
import OutletContainer from "../../utils/components/OutletContainer";
import { instanceApi } from "../../utils/hooks/api";
import { useFeatureFlags } from "../../utils/hooks/useFeatureFlags";
import { chatAtom } from "./atoms/chatAtom";
import { chatMenuAtom } from "./atoms/chatMenuAtom";
import ChatAppBar from "./components/ChatAppBar";
import ChatList from "./components/ChatList";

const WeavyChat = () => {
	const weavy = useWeavy({
		url: "https://014ea6bec6e14b308e2e5ed8d348e192.weavy.io",
		tokenFactory: async () => {
			const { data } = await instanceApi(WeavyApi).getWeavyAccessToken();

			return data?.token;
		},
	});

	const theme = useTheme();
	const isOverLg = useMediaQuery(theme.breakpoints.up("lg"));

	const chatMenuOpen = useAtomValue(chatMenuAtom).open;

	const chatId = useAtomValue(chatAtom).chatId;

	const messengerRef = useRef<HTMLElement>(null);

	const { isFeatureEnabled } = useFeatureFlags();

	useEffect(() => {
		if (messengerRef.current) {
			// @ts-ignore
			console.log("messengerRef.current", messengerRef.current);
			messengerRef.current.shadowRoot?.append(
				Object.assign(document.createElement("STYLE"), {
					innerHTML: `
					wy-conversation-list {
						display: none;
					}
					wy-conversation-appbar {
						display: none;
					}
					.wy-message-me {
						--wy-component-background-color: #52bd8f;
					}
					wy-avatar-header {
						display: none;
					}
					`,
				}) // Object.assign
			);
		}
	}, [chatId, chatMenuOpen]);

	if (isFeatureEnabled("chat") === false) {
		return null;
	}

	return (
		<OutletContainer variant="small">
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					height: "100%",
					maxHeight: "100%",
					[`& wy-messenger > .wy-messenger-layout`]: {
						backgroundColor: "yellow",
					},
				}}
			>
				<ChatAppBar />
				<div className="flex flex-row gap-3 flex-1">
					{(chatMenuOpen || isOverLg) && <ChatList />}
					{(!chatMenuOpen || isOverLg) &&
						(Boolean(chatId) ? (
							<WyMessenger
								conversationId={chatId}
								className="h-[calc(100vh_-_184px)]"
								// @ts-ignore
								ref={messengerRef}
								noCloudFiles
								noMeetings
								noMentions
								key={chatId}
								noThumbnails
							/>
						) : (
							<div className="flex-1 h-[calc(100vh_-_184px)] flex flex-row items-center justify-center">
								<Typography variant="subtitle1" className="text-center">
									Nessuna chat selezionata
								</Typography>
							</div>
						))}
				</div>
			</Box>
		</OutletContainer>
	);
};

export default WeavyChat;
